<script>
	const date = String(new Date().getFullYear());
</script>

<footer>
	<div class="footer__links">
		<a href="/contact">contact</a>
		<a href="https://www.ronanfuruta.com?r=trivia" class="developed" target="_blank"
			>website developed by <span>ronan furuta</span></a
		>
		<a href="https://www.southburlingtonfoodshelf.org/" target="_blank"
			>south burlington food shelf</a
		>
	</div>

	<small>&#169; {date} Pedro Carmolli</small>
</footer>

<style>
	footer {
		display: block;
		text-align: center;
		margin-top: auto;
		padding-top: 3em;

		margin-right: 3em;
		margin-left: 3em;
		margin-bottom: 1em;
	}
	footer a {
		font-variation-settings: 'wght' 400;

		display: block;
	}
	.footer__links {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		width: 100%;
		padding: 1em 0;
		max-width: 60em;
		margin-left: auto;
		margin-right: auto;
	}
	@media only screen and (max-width: 1020px) {
		.footer__links {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 1f 1fr;
			grid-row-gap: 1em;
		}
		.footer__links a {
		}
		.footer__links .developed {
			grid-row: 3;
		}
	}
	.footer__links a {
		margin: 0 1em;
		font-size: 1rem;
		text-align: center;
	}
	.developed {
		text-decoration: none;
	}
	.developed span {
		text-decoration: underline;
	}
</style>

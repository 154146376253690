<script>
	import Donate from '$lib/components/donate.svelte';
	import { beforeNavigate } from '$app/navigation';
	import { fly, slide } from 'svelte/transition';
	import { cubicOut } from 'svelte/easing';
	let open = false;
	let toggleMenu = () => {
		open = !open;
		if (open) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'scroll';
		}
	};
	beforeNavigate(() => {
		open = false;
		document.body.style.overflow = 'scroll';
	});
</script>

<nav class="app__nav">
	<a class="app__nav__brand" href="/">pedro's trivia</a>
	<div class="links">
		<a class="nav__link" href="/library">library</a>
		<a class="nav__link" href="/about">about</a>
		<a class="nav__link" href="/contact">contact</a>
		<a href="/donate" target="_blank" class="btn small donate">donate</a>
	</div>
	<div class="burger">
		<button
			on:click={toggleMenu}
			class="hamburger hamburger--squeeze"
			class:is-active={open}
			type="button"
			aria-label="Menu"
			aria-controls="navigation"
		>
			<span class="hamburger-box">
				<span class="hamburger-inner"></span>
			</span>
		</button>
	</div>
</nav>
{#if open}
	<div class="links--open" transition:slide={{ y: -20, duration: 400, easing: cubicOut, delay: 0 }}>
		<a class="nav__link" href="/library">library</a>
		<a class="nav__link" href="/about">about</a>
		<a class="nav__link last" href="/contact">contact</a>

		<Donate />
	</div>
{/if}

<style>
	@import url('$lib/styles/hamburgers.css');
	.links--open {
		position: absolute;
		top: 5.7rem;
		right: 0;
		left: 0;
		padding: 0em 1em;
		background: white;
		height: 100vh;
		display: flex;
		flex-direction: column;
		border-bottom: 5px solid black;
		z-index: 100;
	}
	.links--open .nav__link {
		padding: 0.5em 0;
	}
	.links--open .last {
		margin-bottom: 5rem;
	}
	.links {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.app__nav {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 1.5em 2.5em;
		height: 2.5rem;
		background: white;
	}

	.app__nav__brand {
		font-variation-settings: 'wght' 700;
		font-size: 1.3rem;
		text-decoration: none;
		margin-right: auto;
	}
	.nav__link {
		margin-right: 1em;
		font-variation-settings: 'wght' 500;
	}

	.donate {
		text-decoration: none;
	}
	.burger {
		display: none;
	}
	@media only screen and (max-device-width: 991px) and (min-device-width: 100px) {
	}
	@media only screen and (max-device-width: 600px) {
		.app__nav {
			padding: 1.5em 1em;
		}
		.links {
			display: none;
		}
		.burger {
			display: block;
		}
	}
</style>
